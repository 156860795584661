export default {
  common: {
    // S Footer
    contact: "Contact",
    docs: "Docs",
    audits: "Audit",
    // E Footer
    undefined: "-",
    success: "Success",
    noData: "No Data",
    price: "Price",
    contractAddress: "Contract Address",
    description: "Description",
    id: "ID",
    balance: "Balance",
    status: "Status",
    congratulations: "Congratulations",
    loading: "Loading",
    participateChannels: "Participate in official channels",
  },

  route: {
    Home: "Home",
    About: "About",
  },

  message: {
    verificationCode1:
      "Please proceed with the verification code request first.",
    verificationCode2: "Please enter your authentication number.",
    verificationCode3:
      "Please check the authentication number and re-enter it.",
    vote: "You cannot vote on your own content.",
    entryPage1: "File size is too large. Please select a file under 10MB.",
    entryPage2: "Please fill in all fields and check the checkboxes.",
    entryPage3: "registered.",
    entryPage4: "Edit is complete.",
    myEntry1: "has been deleted.",
    myEntry2: "It was not deleted. please try again.",
    ref1: " is the referral code.",
    ref2: "Referral code has been copied to your clipboard.",
    ref3: "You cannot enter your own referral code.",
    ref4: "This is a referral code that has already been registered.",
    ref5: "The code you entered is a non-existent referral code.",
    ref6: "Enter the referral code shared with you.",
    referral1: "Saving the Earth's Small but Certain Happiness 🌎🌟",
    referral2: "Practice carbon reduction with friends",
    referral3: "If a friend enters a referral code, the referrer",
    referral4: "1,000 ESG Points",
    referral5: "receives ",
    referral6: "Just by spreading the word",
    referral7: "50 ESG Point",
    referral8: "Referral Code Entry",
    introduction1: "#GlobalWarming #ClimateCrisis #CarbonNeutral",
    introduction2: "A topic you've been pondering about?",
    introduction3: "donation for tree planting",
    introduction4: "Use Module",
    introduction5: "Request 'Stair Climbing' setup locally",
    introduction6:
      "This app is currently available for download on Google Play",
    battleVoteMessage: "you have already voted",
    battleOwnContents: "You can't vote on your own content",
    battleEntry:
      "The entry of stolen item is against our terms of service and not allowed on ECO NFT. The award of the stolen item can be revoked",
    WithdrawalPasswordCheck1: "Please enter at least 6 characters",
    WithdrawalPasswordCheck2: "The passwords do not match",
    WithdrawalPasswordCheck3: "Withdrawal password change is complete",
    codeTransmissionCompleted: "The authentication code has been sent",
    EnterVerificationCode: "Enter Verification Code",
    Complete: "Complete",
    EnterCode: "Enter the verification code in the blank space",
    VerificationCodeRequest: "Verification Code Request",
    resetPassword: "Withdrawal Password Reset",
    GoogleAuthentication: "Google Authentication",
    forgetPassword: "Did you forget your password?",
    sorryChecking: "The system is being checked.",
    serviceChecking: "Battle service is being prepared",
    googleLoginButton: "LOGIN",
    googleLoginTitle:
      "Save the Earth with Zero Quest!🌍🎮Reducing your carbon footprint is our game mission!Earn ESG points for every success!Even more fun, points can be redeemed by exchanging them for ESG tokens.From climbing stairs to riding a bike!With ZeroQuest, you can save the planet and fill your pockets!Let’s make the Earth more enjoyable and healthy together!Start right now! Become a hero of Earth with ZeroQuest!🌟🚀Shh! There are plenty of additional bonuses if you spread the word.",
    swapCaution: "Caution",
    swapCaution1: "You can swap from ESG point 30,000 points or higher.",
    swapCaution2:
      "ESG Token's price is based on a 7-day average price for CoinMarketCap.",
    swapCaution3:
      "Except for ESGP, the balance of ESG and ETH tokens is reflected after the blockchain transaction is completed and therefore does not change immediately.",
    addressCaution1: "We only support the same main net when making a deposit.",
    addressCaution2:
      "Only matic (polygon mainnet) and ESG (Ethereum) mainnet can be deposited and withdrawn.",
    addressCaution3:
      "The service provider is not responsible for any incorrect input by the user.",
    withdraw: "Withdrawal",
    withdrawPassRegister: "Register withdrawal password",
    withdrawPassReset: "Withdrawal Password Reset",
    withdrawPassUpdate: "Reset Password",
    withdrawCount: "Quantity",
    withdrawPassInput: "password",
    withdrawNewPassInput: "New password",
    withdrawPassCheck: "Confirm password",
    withdrawPassCaution1:
      "Password is a total of 6 digits, special character combination",
    withdrawPassCaution2:
      "Please enter the same password in the password check box.",
    withdrawCaution1:
      "When withdrawing, withdrawal is possible only when the main net is the same.",
    withdrawCaution2:
      "The withdrawal will proceed after the withdrawal review.",
    withdrawCaution3: "Withdrawal fee",
    withdrawCaution4: "Mainnet fee applied",
    withdrawBtnRegister: "withdrawal request",
    withdrawError1: "Please enter at least 6 digits.",
    withdrawError2: "Password does not match.",
    withdrawError3: "Please enter your withdrawal address.",
    withdrawError4: "Please enter the withdrawal quantity.",
    withdrawError5: "Please enter the withdrawal password",
    withdrawRequestEnd: "Your withdrawal application has been completed.",
    withdrawRequestValue: "Swap request quantity {value}",
    swapRequestEnd: "The swap request has been completed.",
    agreeTerms:
      "You can use it only if you agree to the required terms and conditions.",
    termsTitle: "Terms of service",
    termsAgreeAll: "We all agree.",
    termsAgreeInfo: "There is an option to agree on content notifications",
    termsAgree01: "[Requirement] Terms of Use",
    termsAgree02: "[Requirement] Privacy policy",
    termsAgree03: "[Select] Contains your consent.",
    termsAgree04: "You already agreed",
    termsBtn: "OK",
    terms: "terms",
    gameLoginInfo: "{name}, welcome.",
    agreeBtn: "Agree",
    nftReward: "NFT Reward",
    rewardBtn: "reward",
    totalReward: "Total reward",
    getReward: "{value} Point exchange completed",
    date: "date",
    time: "time",
    reward: "reward",
    buyWithPoint: "Buy with ESG points",
    ChargingPoint: "ESG Point charging",
    successReferralTitle: "Referral code entered!",
    successReferral: "Your referral has been credited with 1000 ESG points",
    successMinting: "Success NFT Minting!",
    notSuccessMinting:
      "Please convert a higher amount to proceed with the swap",
    howToUse: "Inquiry on how to use",
    successSwap: "Token swap was successful.",
    passwordRegComplated: "Withdrawal password registration completed.",
    passwordUpdateComplated: "Withdrawal password re-registration completed.",
  },

  error: {
    none: "Please use it again later.",
    useAfterLogin: "Please login first",
    notProcessIng: "Transfer is being processed.",
    notEnoughMoney: "The quantity you have is insufficient.",
    notEnoughFee: "The fee is insufficient.",
    notFoundCoin: "Coin not allowed.",
    notFoundWallet: "I can't find my wallet.",
    notEnoughBalance: "You don't have enough coins.",
    notTransferPoint: "Points cannot be transferred.",
    passwordNotMatch: "The withdrawal password does not match.",
    notSupportSwap: "Unsupported swap coin.",
    lessMiniumCostSwap: "Swap quantity is less than minimum quantity.",
    // firstConvertCostSwap: "Please convert first, then proceed with the swap.",
    failedPointSwap: "Point swap failed.",
    commingSoon: "Coming soon.",
    notActivated: "NFT is inactive.",
    notReward: "There are no points to be rewarded.",
    notFoundFlutter: "Not Found 'flutter_inappwebview'",
    notEnoughPoints: "ESG points are lacking.",
    notEnoughGasFee:
      "Minting fees are insufficient. Please contact your administrator.",
    incorrectValue: "Please enter the correct value.",
  },
};
